export const testimonialsData = [
  {
    name: "Awab Ahmd",
    review:
      "It is a very, very, very wonderful application 😁😁 I advise everyone to download it. Thank you very much",
  },
  {
    name: "Stephen Nyapola",
    review:
      "Perfect app with great UI features. Exactly what I was looking for.",
  },
  {
    name: "Ambe Boris Fuhnwi",
    review:
      "This app is very good for those who wants to grow spiritually. I recommend everyone to download this app.",
  },
  {
    name: "Amutha Ramdas",
    review: "I got review from my friend about this App it's great😀👍",
  },
  {
    name: "Elvis spear",
    review: "So far it is good, user friendly very good one",
  },
  {
    name: "Mir Rashid",
    review: "My lifes best app maker free and free love it omg",
  },
  {
    name: "Tiger Bliss",
    review:
      "Amazing thanks for giving us this opportunity to build our own app",
  },
  {
    name: "Relish Enthusiast Adventures",
    review: "Simple UI making the app making process smoother.",
  },
  {
    name: "ShoRyuKen SongKran",
    review:
      "Really awesome. I recommend you try it out. It's easy and beautiful and actually works.",
  },
];
