import image1 from "../assets/why-us/Vector.svg";
import image2 from "../assets/why-us/Vector-1.svg";
import image3 from "../assets/why-us/Vector-2.svg";
import image4 from "../assets/why-us/Vector-3.svg";
import image5 from "../assets/why-us/Vector-4.svg";
import image6 from "../assets/why-us/Vector-5.svg";
import image7 from "../assets/why-us/Vector-6.svg";
import image8 from "../assets/why-us/Vector-7.svg";
import image9 from "../assets/why-us/Vector-8.svg";
import image10 from "../assets/why-us/Vector-9.svg";
import image11 from "../assets/why-us/Vector-10.svg";
import image12 from "../assets/why-us/Vector-11.svg";

export const whyUsListData = [
  {
    title: "User Friendly",
    image: image1,
    description:
      "Create your app with ease using our simple, user-friendly app builder. No coding required!",
  },
  {
    title: "Smart Builder",
    image: image2,
    description: "Very easy to create the app our intuitive smart builder.",
  },
  {
    title: "Element Types",
    image: image3,
    description:
      "Access over 8 different elements, including Website, Map, and more.",
  },
  {
    title: "Download APK & Bundle",
    image: image4,
    description:
      "Download your app as an APK or Bundle file, ready for release on the Play Store.",
  },
  {
    title: "Pre Built Screen",
    image: image5,
    description:
      "Choose from 80+ customizable screen to match your expectations",
  },
  {
    title: "Language Support",
    image: image6,
    description:
      "Access NucleApp in 12 languages, making it a truly global tool.",
  },
  {
    title: "Innovative Features",
    image: image7,
    description:
      "More features like website converter, Custom Logo, Custom Theme, App link, Etc",
  },
  {
    title: "Templates",
    image: image8,
    description:
      "Start quickly with 80+ ready templates designed to ease your workflow.",
  },
  {
    title: "User Information",
    image: image9,
    description:
      "Analyze your data with effective graphs and charts for clear insights.",
  },
  {
    title: "Customer Support",
    image: image10,
    description:
      "We provide support for timely and effective assistance, enhancing customer satisfaction.",
  },
  {
    title: "Send Notification",
    image: image11,
    description:
      "Get real-time alerts on your device and never miss important updates or activities.",
  },
  {
    title: "Security and Privacy",
    image: image12,
    description:
      "Ensure data protection with advanced encryption, fraud detection and strict privacy policies.",
  },
];
