import { useRef } from "react";

import Header from "../components/Header/Header";
import GetStarted from "../components/GetStarted/GetStarted";
import WhyUs from "../components/WhyUs/WhyUs";
import SubscriptionPlans from "../components/SubscriptionPlans/SubscriptionPlans";
import Testimonials from "../components/Testimonials/Testimonials";
import Footer from "../components/Footer/Footer";
import MenuModal from "../components/Menu-Modal";

export default function Home() {
  const modalRef = useRef(null);

  function handleModalOpen() {
    console.log("menu clicked");
    modalRef.current.showModal();
  }

  function handleModalClose() {
    console.log("close clicked");
    modalRef.current.close();
  }

  return (
    <>
      <MenuModal ref={modalRef} onClose={handleModalClose}/>
      <main>
        <Header onOpen={handleModalOpen}/>
        <GetStarted />
        <WhyUs />
        <SubscriptionPlans />
        <Testimonials />
        <Footer />
      </main>
    </>
  );
}
