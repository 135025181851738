import { testimonialsData } from "../../data/testimonials-data";
import "./Testimonials.css";

import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

import noImage from "../../assets/no-image.svg";
import starRating from "../../assets/5-star-rating.svg";
import { useRef, useState, useEffect } from "react";

export default function Testimonials() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [autoPlay, setAutoPlay] = useState(true);
  const isMobile = window.innerWidth <= 768;

  const itemsPerPage = isMobile ? 1 : 3;

  const slicedItems = testimonialsData.slice(
    currentIndex,
    currentIndex + itemsPerPage
  );

  const timer = useRef(null);
  console.log(window.innerWidth);

  useEffect(() => {
    timer.current =
      autoPlay &&
      setTimeout(() => {
        slideRight();
      }, 4000);
  });

  function slideLeft() {
    if (currentIndex === 0) {
      setCurrentIndex(testimonialsData.length - itemsPerPage);
    } else {
      setCurrentIndex(currentIndex - itemsPerPage);
    }
  }

  function slideRight() {
    if (currentIndex === testimonialsData.length - itemsPerPage) {
      setCurrentIndex(0);
    } else {
      setCurrentIndex(currentIndex + itemsPerPage);
    }
  }

  return (
    <div className="testimonials">
      <div className="title">
        <span>Success</span> Stories
      </div>
      <div
        className="testimonial-container"
        onMouseEnter={() => {
          setAutoPlay(false);
          clearTimeout(timer.current);
        }}
        onMouseLeave={() => setAutoPlay(true)}
      >
        <div className="scroll-button left" onClick={slideLeft}>
          <IoIosArrowBack className="arrow-icon" color="white" />
        </div>
        <ul>
          {slicedItems.map((testimonial, index) => (
            <li key={`testimonial-${index}`}>
              <TestimonialCard
                name={testimonial.name}
                description={testimonial.review}
              />
            </li>
          ))}
        </ul>
        <div className="scroll-button right" onClick={slideRight}>
          <IoIosArrowForward className="arrow-icon" color="white" />
        </div>
      </div>
    </div>
  );
}

function TestimonialCard({ name, description }) {
  return (
    <div className="testimonial-card">
      <img className="no-image" src={noImage} alt="" />
      <div className="user-name">{name}</div>
      <img className="rating" src={starRating} alt="" width={150} />
      <div className="desc">{description}</div>
    </div>
  );
}
