import "./Pricing.css";
import { IoArrowBack } from "react-icons/io5";
import greenCheck from "../../assets/green-check.svg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { baseUrl } from "../../constants";

export default function Pricing() {
  const [isBasicPlan, setBasicPlan] = useState(false);

  function handleBasicPlanClick() {
    setBasicPlan(true);
    console.log(`isBasicPlan: ${isBasicPlan}`);
  }

  function handleStandardPlanClick() {
    setBasicPlan(false);
    console.log(`isBasicPlan: ${isBasicPlan}`);
  }

  const navigate = useNavigate();

  function handleLinkClick(url) {
    window.open(url, "_blank", "noopener,noreferrer");
  }

  return (
    <>
      {/* mobile view */}
      <div className="pricing-mobile">
        <div className="top-bar">
          <div className="back-icon" onClick={() => navigate("/")}>
            <IoArrowBack size={25} />
          </div>
          <div className="title">Subscription Plans</div>
        </div>
        <div className="main-container-mobile">
          <div className="price-cards">
            {/* basic-price-card */}
            <div
              className={`basic-price-card ${
                isBasicPlan === true ? "active" : ""
              }`}
              onClick={handleBasicPlanClick}
            >
              <div className="plan-name">BASIC</div>
              <div className="price">₹ 0</div>
              <div className="duration">Free</div>
              <div className="current-plan">• Current Plan</div>
            </div>

            {/* standard-price-card */}
            <div
              className={`standard-price-card ${
                isBasicPlan === false ? "active" : ""
              }`}
              onClick={handleStandardPlanClick}
            >
              <div className="plan-name">STANDARD</div>
              <div className="old-price">₹ 999.00</div>
              <div className="price">₹ 650.00</div>
              <div className="duration">Per Month</div>
            </div>
          </div>
          <div className="feature-list">
            {isBasicPlan === true ? (
              <>
                <Feature feature="3 Apps" />
                <hr />
                <Feature feature="Storage 1 GB" />
                <hr />
                <Feature feature="100 Installs/Account" />
                <hr />
                <Feature feature="3 Trial Apk Build" />
                <hr />
                <Feature feature="Contains Ads" />
                <hr />
                <Feature feature="NucleApp Branding" />
              </>
            ) : (
              <>
                <Feature feature="25 Apps" />
                <hr />
                <Feature feature="Storage 25 GB" />
                <hr />
                <Feature feature="20000 Installs/Account" />
                <hr />
                <Feature feature="10 Apk Build" />
                <hr />
                <Feature feature="Users Information" />
                <hr />
                <Feature feature="Email Chat Support" />
                <hr />
                <Feature feature="No Ads" />
                <hr />
                <Feature feature="No NucleApp Branding" />
              </>
            )}
          </div>
          {isBasicPlan === false && <button
            className="subscribe-to-standard-button"
            onClick={() => handleLinkClick(baseUrl)}
          >
            Subscribe to Standard
          </button>}
        </div>
      </div>

      {/* pc view */}
      <div className="pricing">
        <div className="back-button" onClick={() => navigate("/")}>
          <IoArrowBack size={35} />
        </div>

        <div className="main-container">
          <div className="title">Subscription Plans</div>
          <div className="subscription-plans">
            <BasicPlans />
            <div className="vertical-rule"></div>
            <StandardPlans />
          </div>
          <button
            className="subscribe-to-standard-button"
            onClick={() => handleLinkClick(baseUrl)}
          >
            Subscribe to Standard
          </button>
        </div>
      </div>
    </>
  );
}

function BasicPriceCard() {
  return (
    <div className="basic-price-card">
      <div className="plan-name">BASIC</div>
      <div className="price">₹ 0</div>
      <div className="duration">Free</div>
      <div className="current-plan">• Current Plan</div>
    </div>
  );
}

function StandardPriceCard() {
  return (
    <div className="standard-price-card">
      <div className="plan-name">STANDARD</div>
      <div className="price">₹ 999.00</div>
      {/*<div className="price">₹ 650.00</div>*/}
      <div className="duration">Per Month</div>
    </div>
  );
}

function Feature({ feature }) {
  return (
    <div className="feature">
      <img src={greenCheck} alt="" />
      <div className="feature-name">{feature}</div>
    </div>
  );
}

function BasicPlans() {
  return (
    <div className="basic-plans">
      <BasicPriceCard />
      <div className="feature-list">
        <Feature feature="3 Apps" />
        <hr />
        <Feature feature="Storage 1 GB" />
        <hr />
        <Feature feature="100 Installs/Account" />
        <hr />
        <Feature feature="3 Trial Apk Build" />
        <hr />
        <Feature feature="Contains Ads" />
        <hr />
        <Feature feature="NucleApp Branding" />
      </div>
    </div>
  );
}

function StandardPlans() {
  return (
    <div className="standard-plans">
      <StandardPriceCard />
      <div className="feature-list">
        <Feature feature="25 Apps" />
        <hr />
        <Feature feature="Storage 25 GB" />
        <hr />
        <Feature feature="20000 Installs/Account" />
        <hr />
        <Feature feature="10 Apk Build" />
        <hr />
        <Feature feature="Users Information" />
        <hr />
        <Feature feature="Email Chat Support" />
        <hr />
        <Feature feature="No Ads" />
        <hr />
        <Feature feature="No NucleApp Branding" />
      </div>
    </div>
  );
}
