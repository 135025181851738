import "./Footer.css";
import nucleappLogo from "../../assets/nucleapp-logo.svg";
import facebook from "../../assets/social/facebook.svg";
import instagram from "../../assets/social/instagram.svg";
import X from "../../assets/social/X.svg";
import linkedin from "../../assets/social/linkedin.svg";
import youtube from "../../assets/social/youtube.svg";

export default function Footer() {
  function handleLinkClick(url) {
    window.open(url, "_blank", "noopener,noreferrer");
  }

  return (
    <div className="footer">
      <div className="brand-logo">
        <img src={nucleappLogo} alt="" />
        <span>NucleApp</span>
      </div>

      <div className="address">
        <span>NucleApp Software Private Limited</span>
        <pre>
          {
            "Awfis Space Solutions, \nKondapur \nHyderabad, Telangana, \nIndia - 500084."
          }
        </pre>
        <a href="mailto:support@nucleapp.com">support@nucleapp.com</a>
      </div>

      <div className="social">
        <p>Follow us</p>
        <div id="social-icons">
          <img
            src={facebook}
            alt="Facebook"
            onClick={() =>
              handleLinkClick(
                "https://www.facebook.com/profile.php?id=100094551371370"
              )
            }
          />
          <img
            src={instagram}
            alt="Instagram"
            onClick={() =>
              handleLinkClick("https://www.instagram.com/nucleapp/")
            }
          />
          <img
            src={X}
            alt="X"
            onClick={() => handleLinkClick("https://x.com/NucleApp_")}
          />
          <img
            src={linkedin}
            alt="LinkedIn"
            onClick={() =>
              handleLinkClick(
                "https://www.linkedin.com/company/96164709/admin/dashboard/"
              )
            }
          />
          <img
            src={youtube}
            alt="Youtube"
            onClick={() => handleLinkClick("https://www.youtube.com/@Nucleapp")}
          />
        </div>
      </div>
    </div>
  );
}
