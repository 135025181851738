import "./GetStarted.css";
import watchVideoIcon from "../../assets/watch-video-icon.svg";
import playstore from "../../assets/playstore.svg";
import appstore from "../../assets/appstore.svg";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../constants";

export default function GetStarted() {
  function handleLinkClick(url) {
    window.open(url, "_blank", "noopener,noreferrer");
  }

  const navigate = useNavigate();

  return (
    <div className="get-started">
      <div className="title">App Creates App</div>
      <div className="desc">
        Create Apps easily for your daily use in your mobile devices
      </div>
      <div className="watch-video" onClick={() => navigate('/watch-video')}>
        <img src={watchVideoIcon} alt="" />
        <span>Watch Video</span>
      </div>
      <button
        className="get-started-button"
        onClick={() => handleLinkClick(baseUrl)}
      >
        Get Started
      </button>
      <div className="get-nucleapp">
        <img
          id="playstore"
          src={playstore}
          alt="Get it on Google Play"
          onClick={() => handleLinkClick("https://play.google.com/store/apps/details?id=com.nucleapp")}
        />
        <img
          id="appstore"
          src={appstore}
          alt="Download on the App Store"
          onClick={() => handleLinkClick("https://apps.apple.com/in/app/nucleapp-app-maker-no-code/id6458545224")}
        />
      </div>
    </div>
  );
}
