import Pricing from "./pages/Pricing/Pricing";
import WatchVideo from "./pages/WatchVideo/WatchVideo";
import Home from "./pages/Home";
import {
  Routes,
  Route,
} from "react-router-dom";

// const router = createBrowserRouter([
//   { path: "/", element: <Home /> },
//   { path: "/pricing", element: <Pricing /> },
//   { path: "/watch-video", element: <WatchVideo /> },
// ]);

function App() {
  return (
    <>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="pricing" element={<Pricing />} />
        <Route path="watch-video" element={<WatchVideo />} />
      </Routes>
      {/* <RouterProvider router={router} /> */}
    </>
  );
}

export default App;
