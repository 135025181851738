import "./WhyUs.css";
import { whyUsListData } from "../../data/why-us-data";

export default function WhyUs() {
  return (
    <div className="why-us">
      <div className="why-us-title">
        Why choose <span>NucleApp?</span>
      </div>
      <ul>
        {whyUsListData.map((item, index) => {
          return (
            <li key={`why-us-${index}`}>
              <WhyUsCard
                title={item.title}
                image={item.image}
                desc={item.description}
              />
            </li>
          );
        })}
      </ul>
    </div>
  );
}

function WhyUsCard({ image, title, desc }) {
  return (
    <div className="why-us-card">
      <img src={image} alt="" />
      <div className="title">{title}</div>
      <div className="desc">{desc}</div>
    </div>
  );
}
