import "./Header.css";
import { GiHamburgerMenu } from "react-icons/gi";

import nucleappLogo from "../../assets/nucleapp-logo.svg";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../../constants";

export default function Header({ onOpen }) {
  function handleLinkClick(url) {
    window.open(url, "_blank", "noopener,noreferrer");
  }
  const navigate = useNavigate();

  return (
    <div className="header">
      <div className="brand-logo">
        <img src={nucleappLogo} alt="" />
        <span>NucleApp</span>
      </div>

      <menu>
        <li id="home">Home</li>
        <li onClick={() => navigate("/pricing")}>Pricing</li>
        <li
          onClick={() => handleLinkClick("https://nucleapp.com/privacy-policy")}
        >
          Privacy Policy
        </li>
        <li
          onClick={() =>
            handleLinkClick("https://nucleapp.com/terms-of-service")
          }
        >
          TOS
        </li>
        <li
          onClick={() =>
            handleLinkClick(
              "https://surveyheart.com/form/6477eb7989b2bb5422a02658"
            )
          }
        >
          Report Abuse
        </li>
        <li
          onClick={() =>
            handleLinkClick(
              "https://surveyheart.com/form/6477ea9e89b2bb5422a024ff"
            )
          }
        >
          Contact Us
        </li>
      </menu>

      <div className="sign-in-and-menu">
        <button
          className="sign-in-button"
          onClick={() => handleLinkClick(baseUrl)}
        >
          Sign in
        </button>

        <span id="menu-icon" onClick={onOpen}>
          <GiHamburgerMenu size={30} color="white"  />
        </span>
      </div>
    </div>
  );
}
