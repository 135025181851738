import { IoClose } from "react-icons/io5";
import "./WatchVideo.css";
import { useNavigate } from "react-router-dom";
import nucleappHome from '../../assets/nucleapp-home.mp4';

export default function WatchVideo() {
  const navigate = useNavigate();

  return (
    <div className="watch-video-page">
      <div className="close-button" onClick={() => navigate("/")}>
        <IoClose size={30} color="white" />
      </div>
      <video controls autoPlay>
        <source src={nucleappHome} type="video/mp4"></source>
        Your browser does not support this video.
      </video>
    </div>
  );
}
