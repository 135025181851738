import { forwardRef } from "react";
import { useNavigate } from "react-router-dom";

import closeButton from "../assets/close-button.svg";

const MenuModal = forwardRef(function MenuModal({ onClose }, ref) {
  function handleLinkClick(url) {
    window.open(url, "_blank", "noopener,noreferrer");
  }
  const navigate = useNavigate();

  return (
    <dialog ref={ref} id="menu-modal">
      <div className="modal-container">
        <ul className="menu-items">
          <li id="home" onClick={onClose}>
            Home
          </li>
          <li onClick={() => navigate("/pricing")}>Pricing</li>
          <li
            onClick={() => {
              onClose();
              handleLinkClick("https://nucleapp.com/privacy-policy");
            }}
          >
            Privacy Policy
          </li>
          <li
            onClick={() => {
              onClose();
              handleLinkClick("https://nucleapp.com/terms-of-service");
            }}
          >
            TOS
          </li>
          <li
            onClick={() =>{
              onClose();
              handleLinkClick(
                "https://surveyheart.com/form/6477eb7989b2bb5422a02658"
              );
            }
            }
          >
            Report Abuse
          </li>
          <li
            onClick={() => {
              onClose();
              handleLinkClick(
                "https://surveyheart.com/form/6477ea9e89b2bb5422a024ff"
              );
            }}
          >
            Contact Us
          </li>

          <li>
            <img onClick={onClose} src={closeButton} alt="Close Button" />
          </li>
        </ul>
      </div>
    </dialog>
  );
});

export default MenuModal;
