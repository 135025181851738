import { useNavigate } from "react-router-dom";
import "./SubscriptionPlans.css";

export default function SubscriptionPlans() {
  const navigate = useNavigate();

  return (
    <div className="plans">
      <div className="plans-title">
        <div>Your Ideal Plan</div>
        <span>with us</span>
      </div>
      <div className="plans-desc">
        From Start to Finish, Our Plan meets your Needs
      </div>
      <div className="plan-cards">
        <div className="basic-plan" onClick={() => navigate("/pricing")}>
          <div className="name">Basic Plan</div>
          <div className="duration">Free / Lifetime</div>
        </div>
        <div className="standard-plan" onClick={() => navigate("/pricing")}>
          <div className="name">Standard Plan</div>
          <div className="duration">
            <div id="amount">₹ 999 / </div>
            <div id="per-month">Month</div>
          </div>
          <button
            className="subscribe-button"
            onClick={() => navigate("/pricing")}
          >
            SUBSCRIBE
          </button>
        </div>
      </div>
    </div>
  );
}
